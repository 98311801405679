.custom-message {
  display: flex;
  margin: 0 auto;
  justify-content: center;

  .ant-message-custom-content {
    gap: 12px;
  }
}

.ant-message .ant-message-notice-wrapper {
}

.ant-message-custom-content {
  display: flex !important;
  display: flex;
  background: #ffffff;
  width: 100%;

  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
}

.ant-message-notice {
  .ant-message-notice-content {
    padding: 16px !important;
    font-size: 13px !important;
    border: 1px solid;
  }
}

.custom-message.error {
  color: #a82f2f;
}
.custom-message.success {
  color: #27af97;
}
.custom-message.info {
  color: #27af97;
}
.custom-message.warning {
  color: #a82f2f;
}
